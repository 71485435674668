import type { UserRole } from '@prisma/client'

/**
 * @param rolesStr Roles string from Entra, e.g. "[Investigator, QC]"" or "Admin"
 * @returns Array of roles
 */
export function parseBpsRoles(rolesStr: string): UserRole[] {
  const roles = rolesStr
    .replace(/^\[/, '')
    .replace(/]$/, '')
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean) as UserRole[]

  return roles
}

export function hasBpsRole<User extends { roles: UserRole[] }>(user: User, role: UserRole) {
  if (user.roles.includes('ADMIN')) return true // Admin can do anything as any role

  return user.roles?.includes(role) ?? false
}

export function roleDisplay(role: UserRole) {
  const mapping: Record<UserRole, string> = {
    ADMIN: 'Admin',
    INVESTIGATOR: 'Investigator',
    QC: 'QC',
    FINDLAY_ELIGIBILITY: 'Findlay Eligibility',
  }

  return mapping[role]
}
