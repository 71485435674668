'use client'

import { cn } from '@/lib/utils'
import { Session } from 'next-auth'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { hasBpsRole } from '@core/domain/auth/roles'
import { BpsLogo } from '@/components/ui/icons/BpsLogo'

interface Props {
  session: Session
}

export const WebNavigation = ({ session }: Props) => {
  const isAdmin = hasBpsRole(session.user, 'ADMIN')
  const pathname = usePathname()

  return (
    <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
      <Link href="/" className="flex items-center gap-2 text-lg font-semibold md:text-bas">
        <BpsLogo className="w-36 mt-3 h-fit" />
      </Link>

      {isAdmin && (
        <Link
          href="/admin/client"
          className={cn('text-gray-500 transition-colors hover:text-black', {
            'text-black': pathname.startsWith('/admin'),
          })}
        >
          Admin
        </Link>
      )}

      <Link
        href="/investigation"
        className={cn('text-gray-500 transition-colors hover:text-black', {
          'text-black': pathname.startsWith('/investigation'),
        })}
      >
        Investigations
      </Link>
      <Link
        href="/claim"
        className={cn('text-gray-500 transition-colors hover:text-black', {
          'text-black': pathname.startsWith('/claim'),
        })}
      >
        Claims
      </Link>
      <Link
        href="/control"
        className={cn('text-gray-500 transition-colors hover:text-black', {
          'text-black': pathname.startsWith('/control'),
        })}
      >
        Control
      </Link>
      {isAdmin && (
        <Link
          href="/report"
          className={cn('text-gray-500 transition-colors hover:text-black', {
            'text-black': pathname.startsWith('/report'),
          })}
        >
          Reports
        </Link>
      )}
      {isAdmin && (
        <Link
          href="/dashboard"
          className={cn('text-gray-500 transition-colors hover:text-black', {
            'text-black': pathname.startsWith('/dashboard'),
          })}
        >
          Dashboard
        </Link>
      )}

      {/* Not next/link so the page makes a full load and picks up the Swagger CDN script */}
      <a
        href="/swagger"
        className={cn('text-gray-500 transition-colors hover:text-black', {
          'text-black': pathname.startsWith('/swagger'),
        })}
      >
        API
      </a>
    </nav>
  )
}
