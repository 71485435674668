'use client'

import { PropsWithChildren } from 'react'
import { ClientContext, useClient } from './context'

export const ClientProvider = ({ children }: PropsWithChildren) => {
  const context = useClient()

  return <ClientContext.Provider value={context}>{children}</ClientContext.Provider>
}
