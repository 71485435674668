'use client'

import { PropsWithChildren } from 'react'
import { QueryClient, QueryClientProvider as QueryClientProvider_ } from '@tanstack/react-query'

const queryClient = new QueryClient()

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
  return <QueryClientProvider_ client={queryClient}>{children}</QueryClientProvider_>
}
