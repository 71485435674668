import { unauthenticate } from '@/app/lib/actions/auth'
import { cn } from '@/lib/utils'

interface Props {
  className?: string
}

export const LogoutButton = ({ className }: Props) => {
  return (
    <form action={unauthenticate} className={cn('flex flex-col items-end', className)}>
      <button className="w-full text-start" type="submit">
        Sign Out
      </button>
    </form>
  )
}
