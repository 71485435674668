'use client'

import { Combobox, Form } from '@/components/ui/Form'
import { useClientContext } from '../../providers/ClientProvider/context'
import { usePathname } from 'next/navigation'
import { Option } from '@/components/ui/utils'
import { clientToOption } from '../../providers/ClientProvider/utils'
import type { Client } from '../../providers/ClientProvider/types'

const ELIGIBLE_PATHS = ['/investigation', '/claim', '/dashboard']

/**
 * Select a client for the client context
 */
export const ClientSelector = () => {
  const pathname = usePathname()
  const { clients, isLoadingClients, handleClientSelected, clientForm, selectedClient } = useClientContext()

  if (!ELIGIBLE_PATHS.some((p) => pathname.includes(p))) return null

  const handleSelect = (client_: Option<string, Client> | Option<string, Client>[] | null) => {
    if (!client_) return

    const client = Array.isArray(client_) ? client_[0] : client_

    handleClientSelected(client.data)
  }

  const options = clients?.map((client) => clientToOption(client)) ?? []

  return (
    <Form {...clientForm}>
      <Combobox
        control={clientForm.control}
        name="client"
        placeholder="Client"
        options={options}
        isLoading={isLoadingClients && !selectedClient}
        classes={{ root: 'w-40' }}
        shouldFilter
        onSelect={handleSelect}
      />
    </Form>
  )
}
