import * as React from 'react'
import { SVGProps } from 'react'

export const BpsLogo = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1548.64} height={536.333} viewBox="0 0 1548.64 536.333" {...props}>
    <text
      xmlSpace="preserve"
      style={
        {
          fontVariant: 'normal',
          fontWeight: 300,
          fontSize: 300,
          fontFamily: 'Helvetica',
          InkscapeFontSpecification: 'Helvetica-Light',
          writingMode: 'lr-tb',
          fill: fill ?? '#100f0d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        } as any
      }
      transform="matrix(1.33333 0 0 1.33333 0 343.95)"
    >
      <tspan x="0 168.29799 320.09799 471.89798 623.698 692.09796 743.69373" y={0}>
        {'benefit'}
      </tspan>
    </text>
    <text
      xmlSpace="preserve"
      style={
        {
          fontVariant: 'normal',
          fontWeight: 700,
          fontSize: 300,
          fontFamily: 'Helvetica',
          InkscapeFontSpecification: 'Helvetica-Bold',
          writingMode: 'lr-tb',
          fill: fill ?? '#100f0d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        } as any
      }
      transform="matrix(1.33333 0 0 1.33333 1082.792 343.95)"
    >
      <tspan x="0 83.349609" y={0}>
        {'IQ'}
      </tspan>
    </text>
  </svg>
)
