import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getWindow() {
  if (typeof window === 'undefined') {
    return null
  }
  return window
}

export function downloadFile(url: string, fileName: string) {
  const element = document.createElement('a')
  element.href = url
  element.download = fileName
  element.target = '_blank'
  element.rel = 'noopener noreferrer'
  element.click()
  element.remove()
}
